<!-- <section class="login-bg">

  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-6" [ngStyle]="{'background-image': 'url(assets/images/login/loginnew.jpeg)',
                                        'background-size': 'cover',
                                        'background-position': 'center center',
                                        'display': 'block'}">
        <img class="bg-img-cover bg-center d-none" src="assets/images/login/loginnew.jpg" alt="looginpage">
      </div>
  <div class="login-inner">
      <div class="col-xl-6"><div class="d-flex justify-content-center">
        <img src="assets/images/login/login-left.jpg" class="img-fluid">
      </div></div>

      <div class="login-card">
      
          <div>
            <div>
              <a class="logo"  routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/logo11.png" alt="looginpage">
                <img class="img-fluid for-dark" src="assets/images/logo/logo11.png" alt="looginpage">
              </a>
            </div>
            <div class="login-main"> 
              <form class="theme-form needs-validation" [class.was-validated]="!loginForm.valid"  [formGroup]="loginForm">
                <h4>Sign in to account</h4>
                <p>Enter your email & password to login</p>
                <div class="form-group">
                  <label class="col-form-label">Username</label>
                  <input class="form-control" type="text" required="" placeholder="Username" formControlName="email">
                  <div class="invalid-tooltip" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required">Username is required.</div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********" formControlName="password">
                  <div class="invalid-tooltip" *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">Password is required.</div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                </div>
                <div class="form-group mb-0">
                  <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox">
                    <label class="text-muted" for="checkbox1">Remember password</label>
                  </div>
                  <a [routerLink]="'/auth/resetpassword'" class="link">Forgot password?</a>
                  <button class="btn btn-primary btn-block" type="button" (click)="login()" [disabled]="!loginForm.valid">Sign in</button>
                </div>
                 
              
              </form>
            </div>
          </div>

      </div>
    </div>
    </div>
  </div>
  </section> -->
  
  <div class="login_signup_sec">
    <div class="login_signup">
        <div class="login">
            <div class="login_inner">
                <div class="login_signup_logo">
                    <a href="/"><img src="assets/images/login/logo.svg" alt="" /></a>
                </div>
                <h1>Hello <span>world.</span></h1>
                <p>Best API is online service which is provide Mobile Recharge, Postpaid Bill Payment, Broadband,Water Bill, Gas Bill, Electricity Bill Pay, Money Transfer, AEPS and DMT.</p>
                <div class="login_signup_navigation">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="https://bestapi.in/about-us.html">About</a></li>
                        <li><a href="https://bestapi.in/">Services</a></li>
                        <li><a href="https://bestapi.in">Support</a></li>
                        <li><a href="https://documenter.getpostman.com/view/21310231/2s93zCXztd" target="_blank">API Doc</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="signup">
            <div class="signup_inner">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a href="javascript:void(0)" role="tab" data-toggle="tab" class="nav-link active"><img src="../../../assets/login/images/login.svg" alt="" /> Login </a>
                    </li>
                    <!-- <li class="nav-item">
                        <a href="#signup" role="tab" data-toggle="tab" class="nav-link"><img src="images/signup.svg" alt="" /> Sign Up </a>
                    </li> -->
                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" role="tabpanel" id="login">
                        <div class="login_signup_form">
                            <h2>Sign in to account</h2>
                            <p>Enter your email & password to login</p>
                            <form  [formGroup]="loginForm">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="field">
                                            <label>Username <span>|</span></label>
                                            <input type="username" formControlName="email"   autocomplete="false" novalidate class="form-control" placeholder="John Smith" />
                                            <div class="invalid-tooltip" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required">Username is required.</div>
                                          </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="field">
                                            <label>Password <span>|</span></label>
                                            <input type="password" [type]="show ? 'text' : 'password'" name="login[password]"  formControlName="password"  autocomplete="false" novalidate class="form-control" placeholder="****************" />
                                            <div class="invalid-tooltip" *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">Password is required.</div>
                                            <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                                            <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                                          </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="checkbox_forgot_password">
                                            <div class="check_box">
                                                <label class="check">
                                                    Remember password
                                                    <input type="checkbox" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="forgot_password">
                                                <a href="javascript:void(0)"  (click)="forgetpwd()">Forgot password?</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <button class="submit_btn" (click)="login()" [disabled]="!loginForm.valid" type="submit" form="form1" value="Submit">
                                            <div class="login_signup_button">
                                                <img src="images/arrow-right.svg" alt="" />
                                                <div class="login_signup_button_content">
                                                    Sign In
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
   
        <ng-template #content let-c="close" let-d="dismiss">
          <!-- <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Hi there!</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> -->
          <div class="modal-body">
            <h3>Please enter the 6-digit verification code we sent via SMS:</h3>
            <div class="forgot-img"><img img="" src="assets/otp.png"></div>
            <div class="form-row px-3">
              <div class="form-group mx-auto mt-3">
                <h6>Enter your OTP </h6>
                <div class="otp-wrap">
                  <input #otpInput [type]="onlyNumbers" [(ngModel)]="otp" class="form-control" [attr.maxlength]="otpMaxLength"
                    [attr.minlength]="otpMinLength" (keyup)="keyUpEvent($event)" placeholder="OTP">  
            
                   
                    <div class="mt-3">
                      <span *ngIf="submitOtp">
                        <button type="submit ml-2" class="btn btn-primary-outline" (click)="onSubmitOTP()">Submit OTP</button>
                      </span>
                    </div>
            
                    <div class="ml-2 d-flex mt-3 otp-recd">
                      <span class="mr-2">didn't get the OTP?</span>
                      <a href="javascript:void(0)" (click)="onResendOtp()">Resend OTP</a>
                    </div>
               
                </div>
              </div>
            </div> 
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Save</button>
          </div>
        </ng-template>
       