
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';

@Component({
  selector: 'custom-dynamic-otp',
  templateUrl: './custom-dynamic-otp.component.html',
  styleUrls: ['./custom-dynamic-otp.component.scss']
})
export class CustomDynamicOtpComponent implements OnInit {
  @Input() otpMaxLength: any;
  @Input() otpMinLength: any;
  @Input() onlyNumbers: any;
  @Input() submitOtp: boolean = false; 
  @Output() otpChange: EventEmitter<any> = new EventEmitter();
  @Output() resendOtp: EventEmitter<any> = new EventEmitter(false);
  @Output() submit: EventEmitter<any> = new EventEmitter();

  @ViewChild('otpInput') otpInputElement: ElementRef;

  otp: any;


  constructor() { 
    setTimeout(()=>{ 
      this.otpInputElement?.nativeElement?.focus();
    },0);  
  };

  ngOnInit(): void {
    
  };

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes);
    let data: any
    if(changes) {
      data = changes;
      this.otpMaxLength = data?.otpMaxLength?.currentValue;
      this.otpMinLength = data?.otpMinLength?.currentValue;
      this.onlyNumbers = data?.onlyNumbers?.currentValue;
      this.submitOtp =  data?.submitOtp?.currentValue;
    }
    //console.log(this.otpMaxLength, this.otpMinLength, this.onlyNumbers , this.submitOtp);
  }

  keyUpEvent(event: any) {
    if(event.target.value.length == this.otpMaxLength) {
      this.otpChange.emit(this.otp);
    }
  };

  onResendOtp() {
    //console.log('fire');
    
    this.resendOtp.emit(true);
  };

  onSubmitOTP() {
    this.submit.emit(this.otp)
  }
  
  

}
