import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormModalOverlayRef } from 'src/app/shared/custome/custom-cdk-modal/classes/form-modal.ref';
import { ComponentModalService, FormModalConfig } from 'src/app/shared/custome/custom-cdk-modal/services/component-modal.service';
import { OtpModalComponent } from 'src/app/shared/custome/modals/otp-modal/otp-modal.component';
import { ApiService } from 'src/app/shared/services/api.service ';
import { config } from 'src/app/shared/services/config';
import { NgxToasterService } from 'src/app/shared/helper/toasterNgs.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router'; 
import { LocalStorageService } from 'src/app/shared/helper/local-storage.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  otpToSend: any;
  public forgotform: FormGroup;
  public show: boolean = false;
  fotpSubscription: Subscription;
  fgeneratedOTPSubscription: Subscription;
  fsubmitOTPSubscription: Subscription;
  fgetResendOTPSubscription: Subscription;
  fresendOtpBehavSubscription: Subscription;
  constructor(
    private componentModalService: ComponentModalService,
    private authService: ApiService,
    private fb: FormBuilder,
    private store : LocalStorageService, 
    private route : Router,

    private ngrxToaster: NgxToasterService,
    ) { 
    this.getOtpforLogin();
    this.forgotform = fb.group({
      username: ['', [Validators.required]], 
    });
   }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }


  sendotp(){
    let Data = this.forgotform.value;
    let payload = {
      'username': Data.username, 
    };
    this.authService.postdata(payload,config.auth.forgotpassword).subscribe((resp: any) => { 
      if (resp.responsecode == 1) {
        this.generateOtpRequestModal();
        this.ngrxToaster.showSuccess(resp.message, "Success");
      }else{
        this.ngrxToaster.showError(resp.message, undefined)
      }
    });
  }

  generateOtpRequestModal() {
    let data: any = {
      otpMaxLength: 6,
      otpMinLength: 6,
      onlyNumbers: 'numbers',
      submitOtp: true,
      resendOtp: false,
      generatedOtp: 'credit'

    }; //data to send to the otp component

    let customConfig: FormModalConfig = {
      data: {
        action: data
      },
      isAnimation: {
        inAnimationStyle: 'slideInDown',
        outAnimationStyle: 'slideOutUp'
      },
    };
    const dialogRef: FormModalOverlayRef = this.componentModalService.open<OtpModalComponent>(OtpModalComponent, customConfig);
  };

  
  onOtpChange(value:any) {
    if( value.length === 6){  
      //console.log('Submiteed',value) 
      let Data = this.forgotform.value;
      let payload = {
        'username': Data.username, 
        'otp':value, 
      };
  
      this.authService.postdata(payload,config.auth.validateotp).subscribe((res: any) => {
        if(res.responsecode == 1){
         //console.log(res); 
         this.store.setItem('user', res);
         this.ngrxToaster.showInfo(res.message, undefined); 
      // this.store.set('userDetails', resp);
        this.route.navigate(['auth/changepwd']);
         //this.router.navigate(['/dashboard/default']);
        }else{
          Swal.fire({
            icon: 'error',
            title: res.message
          })
        }
      })
    } 
  }; 
  getOtpforLogin() {
     this.authService.generatedOTP.subscribe(
      {
        next: (value: any) => { 
          if (value) {
            this.otpToSend = value; 
            //console.log('54545454=====>>>>',value)
            this.onOtpChange(value);
          }
        }
      }
    )
  }; 
  resendOTP() {
    this.fresendOtpBehavSubscription = this.authService.resendOTP.subscribe(
      {
        next: (value: any) => {
          //console.log(value) 
        }
      }
    )
  };
 

  ngOnDestroy(): void {
    
    if (this.fotpSubscription) {
      this.fotpSubscription.unsubscribe();
    }

    if (this.fsubmitOTPSubscription) {
      this.fsubmitOTPSubscription.unsubscribe();
    }

    if (this.fresendOtpBehavSubscription) {
      this.fresendOtpBehavSubscription.unsubscribe();
    }

    if (this.fgetResendOTPSubscription) {
      this.fgetResendOTPSubscription.unsubscribe();
    }
    

  }

  
}
