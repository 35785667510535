import { Injectable } from '@angular/core'; 
import { LocalStorageService } from '../helper/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public config ={
    
    settings: {
      layout: '',
      layout_type: '',
      layout_version: '',
      sidebar_type: ''
    },
    color: {
      primary_color: '', 
      secondary_color: ''
    }
  }

  constructor(private store: LocalStorageService) { 
    let storageData = JSON.parse(this.store.getItem('user'));
		 console.log(storageData.data.usersettings[0]);
    setTimeout(() => {
    this.config = {
      settings: {
        layout: storageData.data.usersettings[0].layout,
        layout_type: storageData.data.usersettings[0].layout_type,
        layout_version: storageData.data.usersettings[0].layout_version,
        sidebar_type: storageData.data.usersettings[0].sidebar_type
      },
      color: {
        primary_color: storageData.data.usersettings[0].primary_color, 
        secondary_color: storageData.data.usersettings[0].secondary_color
      }
    }; 
    if(this.config.settings.layout_type == 'rtl')
      document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type); 
    document.documentElement.style.setProperty('--theme-deafult', this.config.color.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.config.color.secondary_color);
  }, 500);

    
  }


}
