import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router'; 
import { config } from 'src/app/shared/services/config'; 
import { AuthService } from './firebase/auth.service';
import { ApiService } from './api.service ';
import { LocalStorageService } from '../helper/local-storage.service';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnInit, OnDestroy {
	permission: any = [];
	menu:any =[];
	private unsubscriber: Subject<any> = new Subject();
	items = new BehaviorSubject<Menu[]>([]);
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router, private store: LocalStorageService,private authService:ApiService) {
		
		
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
		//let storageData = JSON.parse(this.store.getItem('user'));
	// console.log(storageData.data.menu);
		this.authService.postdata('',config.auth.menu).subscribe((resp: any) => {
			console.log(`data will  get rssooo`,resp);
			this.menu = resp.data;
			this.items.next(this.menu);
			 console.log(this.menu);
		})
		// setTimeout(() => {
			
		// 	  console.log(this.menu);
		// 	//this.menu = storageData.data.menu;
		// 	this.items.next(this.menu);
			
		// }, 600);
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}
	ngOnInit(): void {
		
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	 
	MEGAMENUITEMS: Menu[] = [
	];
	LEVELMENUITEMS: Menu[] = [
	];

	// Array

	

	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
