import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxToasterService } from 'src/app/shared/helper/toasterNgs.service';
import { AuthService } from '../auth.service';
import { PasswordReg } from '../../shared/helper/password-reg'; 
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/helper/local-storage.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public changeform: FormGroup;
  public show: boolean = false;
  showErrorBox: boolean = false;
  storagedata:any=[];
  chagepswd: Subscription;
  constructor(
    private authService: AuthService,
    private fb: FormBuilder, 
    private route: Router, 
    private ngrxToaster: NgxToasterService,
    private store:LocalStorageService,
  ) {
    this.changeform = fb.group({
      password: ['', [Validators.required]], 
      confirmPassword: ['', [Validators.required]], 
    });
    this.changeform = this.fb.group({
      // password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      password: ['', [Validators.required, PasswordReg.patternValidator(/\d/, {
        hasNumber: true
      }),
      // check whether the entered password has upper case letter
      PasswordReg.patternValidator(/[A-Z]/, {
        hasCapitalCase: true
      }),
      // check whether the entered password has a lower case letter
      PasswordReg.patternValidator(/[a-z]/, {
        hasSmallCase: true
      }),
      // check whether the entered password has a special character
      PasswordReg.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }
      ),
      Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: PasswordReg.passwordMatchValidator
    });
    let storageData = JSON.parse(this.store.getItem('user'));
    this.storagedata = storageData; 
    //console.log(this.storagedata);
    
    
   }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }
  changePassword(){
    if (!this.changeform.valid) {
      return;
    } else { 
       const formdata = new FormData(); 
        formdata.append('password', this.changeform.get('password').value);
        formdata.append('confirm_password', this.changeform.get('confirmPassword').value); 
        if(this.storagedata.reqtype == 1){
          formdata.append('username', this.storagedata.uid); 
        } 
       let retype = this.storagedata.reqtype;
        this.authService.changePassword(formdata,retype).subscribe((res: any) => {
          if(res){
            if(res.status == true && res.statuscode == 200){ 
              this.ngrxToaster.showInfo(res.message, undefined); 
              sessionStorage.clear();
              this.route.navigate(['auth/login']);
            }else{
              Swal.fire({
                icon: 'error',
                title: res.message
              })
            }
          }
        })

    }
  }
  get f() { return this.changeform.controls; }

}
