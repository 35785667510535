import { Component, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { LocalStorageService } from 'src/app/shared/helper/local-storage.service'; 
import { NgxToasterService } from 'src/app/shared/helper/toasterNgs.service';
import { ApiService } from 'src/app/shared/services/api.service ';
import { config } from 'src/app/shared/services/config'; 
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login-otp-verify',
  templateUrl: './login-otp-verify.component.html',
  styleUrls: ['./login-otp-verify.component.scss'], 
})
export class LoginOtpVerifyComponent implements OnInit {
  length = 6;
  mobileNo: any;
  verifydata: any;
  timerInterval: any;
  display: any; 
  showTimer: boolean = false;
  otps:any;
  storagedata:any;
  loginData:any
  userDetail:any;
  resend:any;
  mobile:any;
  @ViewChild(NgOtpInputComponent, { static: false}) ngOtpInput:NgOtpInputComponent;
  constructor( private store:LocalStorageService,
    private fb: FormBuilder,
    public router: Router,
    private authService: ApiService,
    private ngrxToaster: NgxToasterService,
    private route: Router,) { }

  ngOnInit(): void {
    let storageData = JSON.parse(this.store.getItem('usermob'));
     this.userDetail = JSON.parse(this.store.getItem('userdetail'));
    this.storagedata = storageData.data; 
   
    //console.log(this.userDetail);
    if(this.storagedata){
      this.checkmob()
      let result = JSON.parse(localStorage.getItem('mobileno'));  
      this.mobile = result.phone.replace(/^.{6}/g, 'XXXXXX');

    }
    this.timer(1)
  }

  onOtpChange(otp:any) { 
    this.otps = otp;
    //console.log('this.otps',this.otps);
    
  }


  onOtpVerify() {
      // //console.log(this.otps);
      // this.ngOtpInput.setValue('');
      //this.ngOtpInput.otpForm.disable(); 
      let payload = {
        'otp':  this.otps,
        'latitude': this.userDetail.latitude,
        'longitude':this.userDetail.longitude,
        'username':this.userDetail.username,
        'password':this.userDetail.password,
      };
      this.authService.postdata(payload,config.auth.VerifyOtp).subscribe((resp: any) => {
        //console.log('response',resp);
        if (resp.responsecode == 1) {
          this.store.removeItem('usermob');
          this.store.removeItem('userdetail');
           localStorage.removeItem('mobileno');
          this.ngrxToaster.showInfo(resp.message, undefined); 
          this.store.setItem('user', resp);
          setTimeout(() => {
            this.ngrxToaster.showSuccess(resp.message, "Success");
            this.router.navigate(['/dashboard/default']);
          }, 600);
        

        } else if(resp.responsecode == 5) {
              Swal.fire({
                icon: 'error',
                title: resp.message
              })  
          this.ngOtpInput.setValue('');
        } 
       
      })
      
  }

  resendOtpFn() { 
    this.authService.postdata(this.resend ,config.auth.sendOtpToMobile).subscribe((resp: any) => {
      //console.log('response',resp);
      if (resp.responsecode == 2) {
        this.ngrxToaster.showInfo(resp.message, undefined);  
      } 
    })
  }
 

  timer(minute: any) {
    this.showTimer = true;

    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.showTimer = false;
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  checkmob(){
    //console.log( this.storagedata.secondary);
    
    if(this.storagedata.secondary.length > 0 ){
      this.resend = {
        'phone': this.storagedata.secondary[0].usermob,
        'userid': this.storagedata.secondary[0].user_id,
      }
    }else{
     // //console.log('sent');
      this.resend = {
        'phone': this.storagedata.primary,
        'userid': this.storagedata.userid,
      }; 
        
      this.authService.postdata(this.resend,config.auth.sendOtpToMobile).subscribe((resp: any) => {
        //console.log('response',resp);
        if (resp.responsecode == 2) {
          this.ngrxToaster.showInfo(resp.message, undefined);  
        } 
      })
      
    }
  }
}
