<section class="login-bg">

  <div class="container-fluid">
    <div class="row">

  <div class="login-inner">
      <div class="col-xl-6"><div class="d-flex justify-content-center">
        <img src="assets/images/login/login-left.jpg" class="img-fluid">
      </div></div>

      <div class="login-card">
      
          <div>
            <div>
              <a class="logo"  routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/logo11.png" alt="looginpage">
                <img class="img-fluid for-dark" src="assets/images/logo/logo11.png" alt="looginpage">
              </a>
            </div>
            <div class="login-main"> 
              <form class="theme-form"   [formGroup]="changeform" (submit)="changePassword()">
                <h4>Create Your Password</h4>
                <div class="form-group"    >
                  <label class="col-form-label">New Password</label>
                  <input class="form-control" formControlName="password"  [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********">
                   <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                </div>
                <div *ngIf="showErrorBox || (f.password.touched && f.password.invalid)"
                class="showValBoxList alert-error mt-3">
                <label class="col"
                    [ngClass]="changeform.controls['password'].hasError('required') || changeform.controls['password'].hasError('minlength') ? 'text-danger' : 'text-success'">
                    <i [ngClass]="changeform.controls['password'].hasError('required') ||
    changeform.controls['password'].hasError('minlength') ? 'far fa-times-circle' :
    'far fa-check-circle'"></i>
                    Must be at least 8 characters!
                </label>
                <label class="col"
                    [ngClass]="changeform.controls['password'].hasError('required') || changeform.controls['password'].hasError('hasNumber') ? 'text-danger' : 'text-success'">
                    <i [ngClass]="changeform.controls['password'].hasError('required') ||
    changeform.controls['password'].hasError('hasNumber') ? 'far fa-times-circle' :
    'far fa-check-circle'"></i>
                    Must contain at least 1 number!
                </label>
                <label class="col"
                    [ngClass]="changeform.controls['password'].hasError('required') || changeform.controls['password'].hasError('hasCapitalCase') ? 'text-danger' : 'text-success'">
                    <i [ngClass]="changeform.controls['password'].hasError('required') ||
    changeform.controls['password'].hasError('hasCapitalCase') ? 'far fa-times-circle' :
    'far fa-check-circle'"></i>
                    Must contain at least 1 in Capital Case!
                </label>
                <label class="col"
                    [ngClass]="changeform.controls['password'].hasError('required') || changeform.controls['password'].hasError('hasSmallCase') ? 'text-danger' : 'text-success'">
                    <i [ngClass]="changeform.controls['password'].hasError('required') ||
    changeform.controls['password'].hasError('hasSmallCase') ? 'far fa-times-circle' :
    'far fa-check-circle'"></i>
                    Must contain at least 1 Letter in Small Case!
                </label>
                <label class="col"
                    [ngClass]="changeform.controls['password'].hasError('required') || changeform.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                    <i [ngClass]="changeform.controls['password'].hasError('required') ||
    changeform.controls['password'].hasError('hasSpecialCharacters') ? 'far fa-times-circle' :
    'far fa-check-circle'"></i>
                    Must contain at least 1 Special Character!
                </label>
            </div>
                <div class="form-group">
                  <label class="col-form-label">Retype Password</label>
                  <input class="form-control" formControlName="confirmPassword"  type="password"   required="" placeholder="*********">
              </div>
                <div *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid" class="alert-error">
                  <div *ngIf="changeform.controls['confirmPassword'].hasError('required')">
                      <small><b> Confirm Password is
                              required.</b></small>
                  </div>
                  <div *ngIf="changeform.controls['confirmPassword'].hasError('NoPassswordMatch')">
                      <small><b>Password do not match</b></small>
                  </div>
              </div>
                <div class="form-group mb-0"> 
                  <button class="btn btn-primary btn-block" [disabled]="!changeform.valid"  type="submit">Done</button>
                </div>
                  </form>
            </div>
          </div>

      </div>
    </div>
    </div>
  </div>
  </section>

<!-- <div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">     
        <div class="login-card">
          <div>
            <div>
              <a class="logo"  routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
              </a>
            </div>
            <div class="login-main"> 
              <form class="theme-form"   [formGroup]="changeform" (submit)="changePassword()">
                <h4>Create Your Password</h4>
                <div class="form-group"    >
                  <label class="col-form-label">New Password</label>
                  <input class="form-control" formControlName="password"  [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********">
                   <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                </div>
                <div *ngIf="showErrorBox || (f.password.touched && f.password.invalid)"
                class="showValBoxList alert-error mt-3">
                <label class="col"
                    [ngClass]="changeform.controls['password'].hasError('required') || changeform.controls['password'].hasError('minlength') ? 'text-danger' : 'text-success'">
                    <i [ngClass]="changeform.controls['password'].hasError('required') ||
    changeform.controls['password'].hasError('minlength') ? 'far fa-times-circle' :
    'far fa-check-circle'"></i>
                    Must be at least 8 characters!
                </label>
                <label class="col"
                    [ngClass]="changeform.controls['password'].hasError('required') || changeform.controls['password'].hasError('hasNumber') ? 'text-danger' : 'text-success'">
                    <i [ngClass]="changeform.controls['password'].hasError('required') ||
    changeform.controls['password'].hasError('hasNumber') ? 'far fa-times-circle' :
    'far fa-check-circle'"></i>
                    Must contain at least 1 number!
                </label>
                <label class="col"
                    [ngClass]="changeform.controls['password'].hasError('required') || changeform.controls['password'].hasError('hasCapitalCase') ? 'text-danger' : 'text-success'">
                    <i [ngClass]="changeform.controls['password'].hasError('required') ||
    changeform.controls['password'].hasError('hasCapitalCase') ? 'far fa-times-circle' :
    'far fa-check-circle'"></i>
                    Must contain at least 1 in Capital Case!
                </label>
                <label class="col"
                    [ngClass]="changeform.controls['password'].hasError('required') || changeform.controls['password'].hasError('hasSmallCase') ? 'text-danger' : 'text-success'">
                    <i [ngClass]="changeform.controls['password'].hasError('required') ||
    changeform.controls['password'].hasError('hasSmallCase') ? 'far fa-times-circle' :
    'far fa-check-circle'"></i>
                    Must contain at least 1 Letter in Small Case!
                </label>
                <label class="col"
                    [ngClass]="changeform.controls['password'].hasError('required') || changeform.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                    <i [ngClass]="changeform.controls['password'].hasError('required') ||
    changeform.controls['password'].hasError('hasSpecialCharacters') ? 'far fa-times-circle' :
    'far fa-check-circle'"></i>
                    Must contain at least 1 Special Character!
                </label>
            </div>
                <div class="form-group">
                  <label class="col-form-label">Retype Password</label>
                  <input class="form-control" formControlName="confirmPassword"  type="password"   required="" placeholder="*********">
              </div>
                <div *ngIf="f.confirmPassword.touched && f.confirmPassword.invalid" class="alert-error">
                  <div *ngIf="changeform.controls['confirmPassword'].hasError('required')">
                      <small><b> Confirm Password is
                              required.</b></small>
                  </div>
                  <div *ngIf="changeform.controls['confirmPassword'].hasError('NoPassswordMatch')">
                      <small><b>Password do not match</b></small>
                  </div>
              </div>
                <div class="form-group mb-0"> 
                  <button class="btn btn-primary btn-block" [disabled]="!changeform.valid"  type="submit">Done</button>
                </div>
                  </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->



