import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit { 
  active: any; 
  constructor(private loader: LoadingService) {
    this.loader.isLoading.subscribe((isLoading) => {
      console.log('get behavour',isLoading);
      // this.active = 0;
        if(isLoading){
          this.active = 0;
        }else{
          this.active = 1;
        }
       
        //console.log(this.active);
        
      
    });
  }
  ngOnInit() { }

  ngOnDestroy() { }

} 

  
