<!-- <section class="login-bg">

    <div class="container-fluid">
      <div class="row"> 
    <div class="login-inner">
        <div class="col-xl-6"><div class="d-flex justify-content-center">
          <img src="assets/images/login/login-left.jpg" class="img-fluid">
        </div></div>
  
        <div class="login-card">
        
            <div>
              <div>
                <a class="logo"  routerLink='/'>
                  <img class="img-fluid for-light" src="assets/images/logo/logo11.png" alt="looginpage">
                  <img class="img-fluid for-dark" src="assets/images/logo/logo11.png" alt="looginpage">
                </a>
              </div>
              <div class="login-main"> 
                <form class="theme-form needs-validation" >
                  <h4>Send Otp User Details</h4> 
                  <div class="row">
                    <div class="col-md-12">
                    <label class="col-form-label">Choose Number To Send OTP</label> <br>
                    <div class="row" >
                        <div class="col-md-4">
                            <button   type="button" placement="top"
                            ngbtooltip="Hold" (click)="sendotp(storagedata.primary)"
                            class="btn btn-danger btn-square btn-sm">{{storagedata.primary.replace(storagedata.primary.substring(0,6), 'XXXXXX')}}</button>
                        </div>
                    <div class="col-md-4" *ngFor="let item of storagedata.secondary">
                        <button   type="button" placement="top"
                        ngbtooltip="Hold" (click)="sendotp(item.mobileno)"
                        class="btn btn-danger btn-square btn-sm">{{item.mobileno.replace(item.mobileno.substring(0,6), 'XXXXXX')}}</button>
                    </div>
                  </div>
                </div>
                  </div>  
                </form>
              </div>
            </div>
  
        </div>
      </div>
      </div>
    </div>
</section> -->
<div class="login-bg">
<div class="popup send_otp">
  <div class="popup_inner send_otp_inner">
      <img src="images/logo.svg" alt="" />
      <h5>Send OTP User Details</h5>
      <p>Choose Number TO Send OTP</p>
      <div class="radiobtns"> 
          <ul>
              <li >
                  <label class="checkcontainer">
                    {{storagedata.primary.replace(storagedata.primary.substring(0,6), 'XXXXXX')}}
                      <input type="radio" (click)="sendotp(storagedata.primary)"   name="radio" />
                      <span class="radiobtn"></span>
                  </label> </li>
                  <li *ngFor="let item of storagedata.secondary">
                    <label class="checkcontainer">
                      {{item.mobileno.replace(item.mobileno.substring(0,6), 'XXXXXX')}}
                        <input type="radio" (click)="sendotp(item.mobileno)"  name="radio" />
                        <span class="radiobtn"></span>
                    </label>
                   </li>
          </ul> 
          <!-- <div class="login_signup_form">
              <button class="submit_btn" type="submit" form="form1" value="Submit">
                  <div class="login_signup_button">
                      <img src="images/arrow-right.svg" alt="" />
                      <div class="login_signup_button_content">
                          Send
                      </div>
                  </div>
              </button>
          </div> -->
      </div>
  </div>
</div>
</div>