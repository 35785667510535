import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscriber, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators'; 
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

var today = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  loginUserName: Subject<any> = new Subject();
  generatedOTP: BehaviorSubject<any> =  new BehaviorSubject('');
  resendOTP: BehaviorSubject<any> =  new BehaviorSubject(false);
  chagepswd: BehaviorSubject<any> =  new BehaviorSubject('');
  webmode: BehaviorSubject<any> =  new BehaviorSubject('');

  constructor(private http: HttpClient) {
  }
  
  // function to perform post operation using normal payload 
  post(url: any, body?, headers?, query = "") {
    return new Promise((resolve: any, reject: any) => {  
      this.http.post(environment.baseUrl + `${query}`, body, { observe: "response", headers }).subscribe(
          {
            next: (response: any) => {
              if(response){
                resolve(response); 
              };
            }, error: (error) => {
               reject(error); 
            }
          }
      )
    });
  }
  
  // function to perform post operation using formData type payload and header
  postdata(payload: any, path: any): Observable<any> { 
    // let headers: HttpHeaders = new HttpHeaders();
    //    headers = headers.append('token', '22509F2AE7BA71E4C3FB32AB94B6CEA8');
    return this.http
        .post<any>(environment.baseUrl + path,payload)
        .pipe(retry(0), catchError(this.errorHandl));
  };

  // function to perform post operation using formData type payload and with no header
  postdataWithoutHeader(payload: any, path: any): Observable<any> {
    return this.http
        .post<any>(environment.baseUrl + path['url'],payload)
        .pipe(retry(0), catchError(this.errorHandl));
  };

  
  // handling error 
  errorHandl(error: any) {
    //console.log("error handler ",error);
    
    // this._bloackUiLoaderService.$loader.next(false);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
    } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => new Error('test'));
  }
}
