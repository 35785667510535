import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'Users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'credentials',
    loadChildren: () => import('../../components/credentials/credentials.module').then(m => m.CredentialsModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'switch-api',
    loadChildren: () => import('../../components/switchapi/switchapi.module').then(m => m.SwitchapiModule)
  },
  {
    path: 'report',
    loadChildren: () => import('../../components/report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'kyc',
    loadChildren: () => import('../../components/report/kyc/kyc.module').then(m => m.KycModule)
  },
  {
    path: 'bbps',
    loadChildren: () => import('../../components/report/bbps/bbps.module').then(m => m.BbpsModule)
  },
  {
    path: 'funding',
    loadChildren: () => import('../../components/fund/fund.module').then(m => m.FundModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('../../components/pg/pg.module').then(m => m.PgModule)
  },
  {
    path: 'Adminsettings',
    loadChildren:() => import('../../components/admin-setting/admin-setting.module').then(m => m.AdminSettingModule)

  },
  {
    path: 'commission',
    loadChildren: () => import('../../components/commission/commission.module').then(m => m.CommissionModule)
  },
  {
    path: 'dmt',
    loadChildren: () => import('../../components/report/dmt/dmt.module').then(m => m.DmtModule)
  },  
   
];
