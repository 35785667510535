<!-- <section class="login-bg">

    <div class="container-fluid">
      <div class="row"> 
    <div class="login-inner">
        <div class="col-xl-6"><div class="d-flex justify-content-center">
          <img src="assets/images/login/login-left.jpg" class="img-fluid">
        </div></div>
  
        <div class="login-card">
        
            <div>
              <div>
                <a class="logo"  routerLink='/'>
                  <img class="img-fluid for-light" src="assets/images/logo/logo11.png" alt="looginpage">
                  <img class="img-fluid for-dark" src="assets/images/logo/logo11.png" alt="looginpage">
                </a>
              </div>
              <div class="login-main"> 
                <form class="theme-form needs-validation" >
                  <h4>Verify OTP</h4> 
                  <div class="row">
                    <div class="col-md-12">
                        <p class="py-3 px-3">We have sent you OTP to your registered phone number for verification</p>
                        <div class="justify-content-center d-flex">
                          <ng-otp-input    (onInputChange)="onOtpChange($event)" [config]="{length:length,allowNumbersOnly:true}"></ng-otp-input>
                        </div> 
                        <div class="d-flex justify-content-center">
                        <button  type="submit" (click)="onOtpVerify()" class="tn btn-danger btn-pills btn-lg "  >Verify Otp</button>
                        </div>
                
                </div>
                  </div>  
                </form>
              </div>
            </div>
  
        </div>
      </div>
      </div>
    </div>
    </section> -->
    
  <div class="login-bg">
  <div class="popup submit_otp">
      <div class="popup_inner send_otp_inner">
          <img src="images/logo.svg" alt="" />
          <h5>Submit OTP</h5>
          <p>Please enter the 6-digit verification code we sent voa SMS:</p>
          <span>{{mobile}}</span>
          <div class="login_signup_form">
              <form>
                  <div class="row">
                      <div class="col-lg-12">
                          <div class="field"> 
                              <ng-otp-input class="centercl"   (onInputChange)="onOtpChange($event)" [config]="{length:length,allowNumbersOnly:true,
                                inputClass:'otp-input-cl'}"></ng-otp-input>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <button class="submit_btn" type="submit" form="form1" value="Submit">
                              <div class="login_signup_button">
                                  <img src="images/arrow-right.svg" alt="" />
                                  <div class="login_signup_button_content" (click)="onOtpVerify()">
                                      Submit
                                  </div>
                              </div>
                          </button>
                      </div>
                  </div>
              </form>
      
              <!-- <p class="resend_otp">Didn’t get the OTP? <a href="#"  (click)="onOtpVerify()">Resend OTP</a></p> -->
          </div>
      </div>
  </div>
  </div>