
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs'; 
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';  
import { catchError, map } from 'rxjs/operators'; 
import { LoadingService } from '../services/loading.service';
import { LocalStorageService } from '../helper/local-storage.service';
@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  loginSession: any;
  private requests: HttpRequest<any>[] = [];
  constructor(private route: Router, private loader: LoadingService,   private store:LocalStorageService,) {}

  removeRequest(req: HttpRequest<any>) {
    const isLoader: any = req.headers.get('isLoader') == 'true' ? 0 : 1;
    const i = isLoader ? this.requests.indexOf(req) : 0;
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loader.isLoading.next(this.requests.length > 0);
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
     

      let session  = this.store.getItem('user') ? JSON.parse(this.store.getItem('user')):null;
      // //console.log(request.url);
     this.loginSession = session?.authtoken;
    // //console.log(this.loginSession);
    if (request.url.includes("auth/login") || request.url.includes('auth/forgot-password') ||request.url.includes('auth/verify') ||request.url.includes('auth/validate-otp') ||request.url.includes('upload/upload-image') ) { //header is not needed in login api
      request = request.clone({
        headers: request.headers.set('token', '22509F2AE7BA71E4C3FB32AB94B6CEA8'),
      })
    }
    else if (this.loginSession != undefined) {
      ////console.log("Setting Token")

      //this.loader.isLoading.next(true);

      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.loginSession)
        .set('token', '22509F2AE7BA71E4C3FB32AB94B6CEA8')
      })
    } 
    const isLoader: any = request.headers.get('isLoader') == 'true' ? 0 : 1;
    this.loader.isLoading.next(isLoader);

    return next.handle(request).pipe(
      catchError(err => {
        console.log("err.msg", err);
        //this.removeRequest(request);
        
        this.loader.isLoading.next(this.requests.length > 0);
        if (err.status === 401 || err.status === 2001) {
          // this.authCoreService.logout();
          // this.authCoreService.clearlocalsession();
          this.removeRequest(request);
          this.store.clearStorage();
          this.route.navigate(['/auth/login']);
          // window.location.reload();
          // this.authCoreService.isSessionTimeOut.next(true);
         
        } else if (err.status === 500) {
          ////console.log("err.msg", err.msg);
          this.removeRequest(request);
        
          // this.toaster.showError(err.msg, 'error')
        }
        const error = err.error.message || err.statusText;
        ////console.log("error", error);
        return throwError(() => error)
      }),
      map(event => {
        if (event instanceof HttpResponse) {
        }
       //this.loader.hide();
       if(event['status'] == 200){
        this.removeRequest(request);
       }
       if(event['status'] == 203){
        this.removeRequest(request);
       }
        
        return event;
      })
    );
  }
}
