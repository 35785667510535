import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/helper/local-storage.service'; 
import { NgxToasterService } from 'src/app/shared/helper/toasterNgs.service';
import { ApiService } from 'src/app/shared/services/api.service ';
import { config } from 'src/app/shared/services/config'; 

@Component({
  selector: 'app-login-otp-send',
  templateUrl: './login-otp-send.component.html',
  styleUrls: ['./login-otp-send.component.scss']
})
export class LoginOtpSendComponent implements OnInit {
    storagedata:any
    public loginOtpForm: FormGroup;
      constructor(
       private store:LocalStorageService,
       private fb: FormBuilder,
       public router: Router,
       private authService: ApiService,
       private ngrxToaster: NgxToasterService,
       private route: Router,
      ) { }

  ngOnInit(): void {
    let storageData = JSON.parse(this.store.getItem('usermob'));
    this.storagedata = storageData.data; 
    
  }


  sendotp(val:any){
    // //console.log(this.storagedata.secondary[0].user_id);
    // //console.log(val);
    let payload = {
      'phone': val,
      'userid': this.storagedata.secondary[0].user_id,
    };
    localStorage.setItem('mobileno',JSON.stringify(payload));
    this.authService.postdata(payload,config.auth.sendOtpToMobile).subscribe((resp: any) => {
      // console.log('response',resp);
      
      if (resp.responsecode == 2) {
        this.ngrxToaster.showInfo(resp.message, undefined); 
        this.route.navigate(['auth/verifyotp']);
      } 
    })
  }

}
