import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	tokenFromUI: string = "Bes7123343445345";
	encrypted: any = "";
	decrypted: any;
  
	request: string;
	responce: string;
	constructor(private cookiesService: CookieService) { }
 
    // saving or storing items to the local storage
	setItem(key: string, item: any,storage="local") {
		this.encryptUsingAES256(key, item,storage);
		//localStorage.setItem(key, JSON.stringify(item));
	}

    // getting item from local storage
	getItem(key: string) {
		let encryptedData = localStorage.getItem(key);
		let data = this.decryptUsingAES256(encryptedData);
		return data;
	}

    // clearing everything from local storage
	clearStorage() {
		localStorage.clear();
	}

    // removing an item from local storage
	removeItem(key: string) {
		localStorage.removeItem(key);
	}

	encryptUsingAES256(key, request, storage?) {
		// //console.log("request to encrypt ", request)
		let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
		let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
		let encrypted = CryptoJS.AES.encrypt(
		  JSON.stringify(request), _key, {
		  iv: _iv,
		  mode: CryptoJS.mode.ECB,
		  padding: CryptoJS.pad.Pkcs7
		});
		let d = encrypted.toString();
		// //console.log("enck", d)
		if(storage == 'cookies'){
		  this.cookiesService.set(key, d)
		} else if(storage == 'local') {
		  localStorage.setItem(key, d)
		}
		sessionStorage.setItem(key, d);
	  };
	
	  decryptUsingAES256(encryptedData): any {
		// let data = JSON.parse(encryptedData);
		if(encryptedData){
		  let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
		  let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
	  
		  return CryptoJS.AES.decrypt(
			encryptedData, _key, {
			keySize: 16,
			iv: _iv,
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		  }).toString(CryptoJS.enc.Utf8);
		}
	 
	  };

}