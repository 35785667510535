 
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
  
import { FormModalOverlayRef } from 'src/app/shared/custome/custom-cdk-modal/classes/form-modal.ref';
import { COMPONENT_MODAL_DATA } from 'src/app/shared/custome/custom-cdk-modal/tokens/component-data.token';
import { ApiService } from 'src/app/shared/services/api.service ';

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss']
})
export class OtpModalComponent implements OnInit {
 
  otpMaxLength: any;
  otpMinLength: any;
  submitOtp: boolean;
  onlyNumbers: boolean;
  generatedOtp: any;
  resendOtp:any;
  constructor( 
    private route: Router,
    public dialogRef: FormModalOverlayRef, 
    private authService: ApiService, 
    @Inject(COMPONENT_MODAL_DATA) public data: any ) {
      //console.log(this.data)
      this.otpMaxLength = this.data?.data?.action?.otpMaxLength;
      this.otpMinLength = this.data?.data?.action?.otpMinLength;
      this.submitOtp = this.data?.data?.action?.submitOtp;
      this.resendOtp = this.data?.data?.action?.resendOtp;
      this.onlyNumbers = this.data?.data?.action?.onlyNumbers;
      this.generatedOtp = this.data?.data?.action?.generatedOtp;
   };

   ngOnInit(): void {
     
   };

   toFormGroup(elements) {
    const group: any = {};

    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event, index) {
    // //console.log(event.target.length)
    // let pos = index;
    // if (event.keyCode === 8 && event.which === 8) {
    //   pos = index - 1 ;
    // } else {
    //   pos = index + 1 ;
    // }
    // if (pos > -1 && pos < this.formInput.length ) {
    //   this.rows._results[pos].nativeElement.focus();
    // }

  }

  // when user click on submit in otp we get the input value which is passed from the custom otp component 
  onSubmit(otp) {
    //console.log(otp)
    //console.log("submitted otp ") 

   // getting and sending otp for credeit card pay bill
    if(this.generatedOtp  === 'credit'){
      if(otp) {
        this.authService.generatedOTP.next(otp);  
        otp = '';
        //console.log(otp);
        
        // this.transactionService.remitterBeneRegister.next(true); 
        this.closeComponentModal();
      }
    };

      // getting and sending otp for dmt kyc customer register
   
   
  };

  onResendOtp(event){
    //console.log(event,'dsfsf')
    if(true){
      if(this.generatedOtp  === 'credit'){  
        this.authService.resendOTP.next(this.generatedOtp);
        }
      
    }
    }


  closeComponentModal(){
    this.dialogRef.close(this.data);
  };

}
